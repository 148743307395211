import { RouterProvider, createBrowserRouter, Outlet } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Account from "../pages/Account";
import '../pages/Login.css';
import ProductMiniatureSelector from "../components/products/ProductMiniatureSelector";
import Home from "../pages/Home";
import Licenses from "../pages/Licenses";
import Processes from "../pages/Processes";
import ProcessesDEV from "../pages/ProcessesDEV";
import ProductForm from "../components/products/ProductForm";
import Storage from "../pages/Storage";
import FileUploader from "../pages/Upload";
import Test from "../pages/Test";
import LicsProvider from "../provider/licsProvider";
import ProcessDetail from "../pages/ProcessDetail";
import PcloudAuth from "../pages/pcloudAuth";
const Routes = () => {
  const { token } = useAuth();

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "",
          element: <Home />,
        },
        {
          path: "/storage",
          element: <Storage />
        },
        {
          path: "/account",
          element: <Account />
        },
        {
          path: "/products",
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <div><Home /><Outlet /></div>,
            },
            {
              path: ":domain",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <ProductMiniatureSelector />,
                },
                {
                  path: ":app",
                  element: <ProductForm />
                }]
            }
          ],

        },
        {
          path: "/processes",
          element: <Processes />
        },
       { path:"/process-detail",
        element: <ProcessDetail/> 
      },
        {
          path: "/processes/unstable",
          element: <ProcessesDEV />
        },
        {
          path: "/licenses",
          element: <LicsProvider><Licenses /></LicsProvider>,
        },
        {
          path: "/upload",
          element: <FileUploader />,
        },
        {
          path: "/logout",
          element: <Logout />,
        },
        {
          path: "/test",
          element: <Test />,
        }

      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: "/login",
      element: <Login />,
    }]

  const routesForAllUsers = [
    {
      path: "/test/pcloud",
      element: < PcloudAuth />,
    }
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
    ...routesForAllUsers,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
