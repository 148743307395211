import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "./authProvider";
import { log, LogLevel } from '../utils/Logger';
import { useTCP } from './TCPProvider';

const procContext = createContext();

const ProcProvider = ({ children }) => {

  const { token } = useAuth()
  const { TCPApp } = useTCP()
  const [procs, setProcs] = useState({})
  const [loadingProc, setLoadingProc] = useState(true);



  const updateProc = () => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios.post(TCPApp + '/processes', {
        "items_per_page": 10000
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          log(LogLevel.INFO, response.status)
          if (response.status === 200) {
            const jsonResponse = response.data
            if (jsonResponse.hasOwnProperty('processes')) {
              log(LogLevel.INFO, "response.data")
              log(LogLevel.INFO, response.data["processes"])
              setProcs(jsonResponse["processes"])
            }
            else { setProcs([]) }
            setLoadingProc(false)
          } else {
            log(LogLevel.INFO, 'Erreur de requête. Statut :', response.status);
          }
        })
        .catch(error => {
          log(LogLevel.ERROR, 'Une erreur s\'est produite :', error);
        }).finally(setLoadingProc(false));
    }
  }

  useEffect(() => {
    setLoadingProc(true)
    updateProc()
  }, [token]);

  useEffect(() => {
    const intervalId = setInterval(updateProc, 30000);
    return () => clearInterval(intervalId);
  }, []);

  const contextValue = useMemo(
    () => ({
      procs,
      setProcs,
      loadingProc,
      updateProc
    }),
    [procs]
  );

  // Provide the authentication context to the children components
  return (
    <procContext.Provider value={contextValue}>{children}</procContext.Provider>
  );
};

export const useProc = () => {
  return useContext(procContext);
};

export default ProcProvider;
