
import { useState, useEffect } from "react";
import { useSocket } from "../../provider/wsProvider";
import Waiting from "../Waiting";
import { useAuth } from "../../provider/authProvider";
import { useTCP } from "../../provider/TCPProvider";
import axios from "axios";

import { log, LogLevel } from '../../utils/Logger';
export default function CreateFolderModale({ name, toggleModal, handleAction }) {

  var segments = name.split("/");

  const [FolderName, setFolderName] = useState(segments[-1]);

  const [messagesList, setMessagesList] = useState([]);
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setLoading] = useState(false)
  const [mid, setMid] = useState();
  const { token } = useAuth()
  const { TCPData } = useTCP()
  const handleChange = (e) => {
    setFolderName(e.target.value);
  };

  const handleSave = () => {

    try {
      var newname = name + FolderName;
      const correctname = newname.replace(' ', '_');
      console.log("CORRECT NAME")
      console.log(correctname)
      const body = { "uri": correctname };
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = axios.post(TCPData + '/dir', JSON.stringify(body), {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          log(LogLevel.INFO, response)
          if (response.status >= 200 && response.status < 300) {
            log(LogLevel.INFO, "ok")
          } else {
            log(LogLevel.INFO, 'Erreur de requête. Statut :', response.status);
          }
        })
    } catch (error) {
      // Gérer les erreurs
      log(LogLevel.ERROR, 'Erreur lors de la requête POST :', error);
    }
    toggleModal();
    handleAction();
  };

  return (
    <>
      {isLoading ?
        <>
          <Waiting size="50" />
        </>
        :
        <>
          <input
            type="text"
            value={FolderName}
            onChange={handleChange}
            placeholder="Folder name"
            style={{ margin: "10px", width: '90%' }}
          />
          <div className="modal-buttons">
            <button style={{ margin: '8px' }} onClick={toggleModal}>Cancel</button>
            <button style={{ margin: '8px' }} onClick={handleSave}>Submit</button>
          </div>
        </>

      }
    </>
  );

}
