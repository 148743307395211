import React, { useState, useEffect } from 'react';
import DarkTable from '../components/DisplayTableProcesses';
import Waiting from "../components/Waiting";
import { useProc } from "../provider/procsProvider";

export default function Processes() {

  const { procs, loadingProc,updateProc } = useProc();

  useEffect(()=>{
    updateProc();
  },[])

  return (
    <div data-testid='processes-page-component'>
      {loadingProc ? (
        <Waiting />
      ) : (
        procs && procs?.length !== 0 ? (
          <div data-testid='processes-page-darktable' style={{ padding: '30px', borderRadius: '3px', backgroundColor: 'rgba(255, 255, 255, 0.9)', maxHeight: 'calc(1.2em * 35)', overflowY: 'auto' }}>
            <DarkTable data={procs} />
          </div>) : <div data-testid='processes-page-noprocs' style={{ color: "#fff" }}>Please go to our <a href="/" style={{ color: "#fff" }}>Products</a> page to run your first process ! </div>
      )}
    </div >
  );
}
